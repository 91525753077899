import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Grid, Container, TextField, Divider } from '@mui/material';
import { styled } from '@mui/system';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CasinoIcon from '@mui/icons-material/Casino';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

// Colors and Fonts
const colors = {
  primary: '#1B1F38', // Very dark blue
  accent: '#F76C5E', // Bright Orange
  background: '#101221', // Darker background
  textPrimary: '#D1D5DB', // Lighter off-white
  textSecondary: '#9AA0BC', // Lighter Gray
  buttonHover: '#B34C3F', // Darker Orange
};

const fonts = {
  heading: "'Playfair Display', serif", // A classic, luxurious font for headings
  body: "'Roboto', sans-serif", // Clean font for body text
};

// Styling Components
const GradientBackground = styled(Box)(({ theme }) => ({
  background: `linear-gradient(135deg, ${colors.background} 0%, #1B1F38 100%)`, // Removed background image
  minHeight: '100vh',
  padding: '3rem 0',
  color: colors.textPrimary,
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  fontFamily: fonts.body,
  overflowX: 'hidden', // Prevent horizontal overflow
  [theme.breakpoints.down('sm')]: {
    padding: '2rem 0',
  },
}));

const SectionContainer = styled(Container)(({ theme }) => ({
  padding: '2rem 0', // Reduced padding
  '@media (max-width: 600px)': {
    padding: '1.5rem 0',
  },
}));

const LineDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: colors.accent,
  height: '2px',
  margin: '1rem 0', // Reduced margin
  '@media (max-width: 600px)': {
    margin: '0.5rem 0',
  },
}));

// New styles for headings and text to improve typography and visual hierarchy
const StyledHeading = styled(Typography)(({ theme }) => ({
  fontFamily: fonts.heading,
  color: colors.accent,
  marginBottom: '1.5rem',
  fontWeight: 'bold',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.8rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '2.2rem',
  },
}));

const StyledSubheading = styled(Typography)(({ theme }) => ({
  fontFamily: fonts.heading,
  color: colors.accent,
  marginBottom: '1rem',
  fontWeight: 'bold',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.8rem',
  },
}));

const TestimonialBox = styled(Box)(({ theme }) => ({
  padding: '2rem',
  margin: '0 auto 2rem auto',
  border: `2px solid ${colors.accent}`,
  borderRadius: '10px',
  maxWidth: '700px',
  backgroundColor: colors.primary,
  boxSizing: 'border-box',
  overflow: 'visible',
  zIndex: 1,
  transition: 'transform 0.3s',
  '&:hover': {
    transform: 'scale(1.02)',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '1rem',
    maxWidth: '90%',
    margin: '0 auto 1rem auto',
  },
}));

const SliderContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: '2rem', // Adjust as needed
}));

// SEO Meta Tags Component
const MetaTags = () => {
  useEffect(() => {
    document.title = "DepositZilla - Experience the Thrill";
    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = 'Experience instant withdrawals, over 200 games, and play anywhere with DepositZilla.';
    document.head.appendChild(metaDescription);
    // Add more meta tags as needed
  }, []);

  return null;
};

const EmailForm = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    console.log(process.env.REACT_APP_GOOGLE_SHEETS_URL);
  }, []);

  const handleEmailSubmit = async (e) => {
    e.preventDefault();

    const googleSheetsUrl = "https://script.google.com/macros/s/AKfycbysXrQ20fTDsyjVklaORWMt1339iptISdmZsYvw3aMvHb9rytwnsb9hSmUqtg7ifSRd/exec";

    if (!googleSheetsUrl) {
      console.error('Google Sheets URL is not defined.');
      setMessage('There was an error with your submission.');
      return;
    }

    try {
      await fetch(googleSheetsUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        mode: 'no-cors',
        body: new URLSearchParams({
          email: email,
        }),
      });

      setMessage('Email submitted successfully!');
      setEmail(''); 
    } catch (error) {
      console.error('Request failed', error);
      setMessage('There was an error with your submission.');
    }
  };

  return (
    <form onSubmit={handleEmailSubmit}>
      <Box display="flex" justifyContent="center" alignItems="center" mt={2} sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
        <TextField
          label="Enter your email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ backgroundColor: '#FFFFFF', borderRadius: '0.25rem', width: '300px' }}
          required
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{
            ml: { sm: '1rem' },
            mt: { xs: '1rem', sm: '0' },
            height: '3.5rem',
            borderRadius: '0.25rem',
            padding: '0 1rem',
            backgroundColor: colors.accent,
            '&:hover': { backgroundColor: colors.buttonHover },
          }}
        >
          Submit
        </Button>
      </Box>
      {message && <Typography variant="subtitle1" sx={{ mt: '0.5rem', color: colors.textSecondary }}>{message}</Typography>}
    </form>
  );
};

const Main = () => {
  useEffect(() => {
    AOS.init({
      duration: 800, 
      easing: 'ease-out-cubic', 
      once: true, 
      anchorPlacement: 'top-bottom', 
    });
  }, []);

  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
    centerMode: false,
    touchThreshold: 10,
    draggable: true,
    swipe: true,
    touchMove: true,
    waitForAnimate: false,
    afterChange: current => setCurrentSlide(current),
    customPaging: i => (
      <div
        style={{
          width: i === currentSlide ? '12px' : '8px',
          height: i === currentSlide ? '12px' : '8px',
          borderRadius: '50%',
          backgroundColor: i === currentSlide ? colors.accent : colors.textSecondary,
          margin: '0 4px',
          transition: 'all 0.3s',
        }}
      />
    ),
    appendDots: dots => (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '0' }}>
        {dots}
      </div>
    ),
    responsive: [
      {
        breakpoint: 600,
        settings: {
          centerMode: false,
          centerPadding: '0px',
        },
      },
    ],
  };

  const testimonialItems = [
    {
      text: "I love the variety of games here, from poker to slots and everything in between. The staff is always super friendly and helpful, making it a great experience every time I play. It's so easy to get started and the bonuses are fantastic!",
      name: "John D.",
    },
    {
      text: "Quick and easy withdrawals are a game-changer for me. I've never had any issues getting my money, and the customer support is top-notch. Plus, the range of sports betting options keeps me coming back. Highly recommend to any gaming enthusiast!",
      name: "Jane S.",
    },
    {
      text: "The no hidden fees policy is a huge plus. Deposits and withdrawals are quick and straightforward, which is perfect for someone like me who values efficiency. The variety of games keeps it exciting, and I especially enjoy the live dealer options.",
      name: "Mike B.",
    },
    {
      text: "What I love most about this platform is the community. The poker club here is second to none, and I've made some real friends along the way. The games are always exciting, and the promotions make it even better. Highly recommend!",
      name: "Anna L.",
    },
    {
      text: "Great customer service and a variety of games make this my go-to site for online gaming. Whether it's sports betting, poker, or slots, there's always something fun to do. The bonuses are generous, and the overall experience is fantastic.",
      name: "Chris M.",
    },
  ];

  return (
    <GradientBackground>
      <MetaTags /> {/* Include SEO Meta Tags */}
      {/* Get in Touch Section */}
      <SectionContainer data-aos="fade-up">
        <StyledHeading variant="h2">
          Get in Touch
        </StyledHeading>
        <Box mt={3} sx={{ backgroundColor: colors.primary, padding: '1.5rem', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)' }}>
          <EmailForm />
          <Button
            variant="contained"
            color="primary"
            href="https://www.messenger.com/t/159575217231906/?messaging_source=source%3Apages%3Amessage_shortlink&source_id=1441792&recurring_notification=0"
            target="_blank"
            sx={{
              marginTop: '1rem',
              backgroundColor: colors.accent,
              fontWeight: 'bold',
              textTransform: 'none',
              padding: '1rem 2rem',
              '&:hover': { backgroundColor: colors.buttonHover },
            }}
          >
            Chat with Us on Messenger
          </Button>
        </Box>
        <LineDivider />
      </SectionContainer>
      {/* Experience the Thrill Section */}
      <SectionContainer data-aos="fade-up">
        <StyledHeading variant="h4">
          Experience the Thrill
        </StyledHeading>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <MonetizationOnIcon sx={{ fontSize: '4rem', color: colors.accent, marginBottom: '1rem' }} />
            <StyledSubheading variant="h5">
              Instant Withdrawals
            </StyledSubheading>
            <Typography variant="body2" sx={{ color: colors.textPrimary }}>
              Get your money in minutes or less.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <CasinoIcon sx={{ fontSize: '4rem', color: colors.accent, marginBottom: '1rem' }} />
            <StyledSubheading variant="h5">
              Over 200 Games
            </StyledSubheading>
            <Typography variant="body2" sx={{ color: colors.textPrimary }}>
              Poker, Slots, Sports Betting & more.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <MobileFriendlyIcon sx={{ fontSize: '4rem', color: colors.accent, marginBottom: '1rem' }} />
            <StyledSubheading variant="h5">
              Play Anywhere
            </StyledSubheading>
            <Typography variant="body2" sx={{ color: colors.textPrimary }}>
              Bring the casino to you.
            </Typography>
          </Grid>
        </Grid>
        <LineDivider />
      </SectionContainer>
      {/* Testimonials Section */}
      <SectionContainer data-aos="fade-up" className="testimonial-section">
        <StyledHeading variant="h4">
          What Players Are Saying
        </StyledHeading>
        <SliderContainer>
          <Slider {...settings}>
            {testimonialItems.map((item, index) => (
              <TestimonialBox key={index}>
                <Typography variant="body1" sx={{ marginBottom: '1rem', color: colors.textPrimary, textAlign: 'left', lineHeight: 1.6 }}>
                  {item.text}
                </Typography>
                <Typography variant="subtitle2" sx={{ color: colors.accent, textAlign: 'right' }}>
                  — {item.name}
                </Typography>
              </TestimonialBox>
            ))}
          </Slider>
        </SliderContainer>
        <LineDivider />
      </SectionContainer>
      {/* Visit Our Partners Section */}
      <SectionContainer data-aos="fade-up">
        <StyledHeading variant="h4">
          Visit Our Partners
        </StyledHeading>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} md={4}>
            <SportsSoccerIcon sx={{ fontSize: '4rem', color: colors.accent, marginBottom: '1rem' }} />
            <Button
              variant="contained"
              color="primary"
              sx={{
                width: '100%',
                backgroundColor: colors.accent,
                padding: '1rem',
                textTransform: 'none',
                fontFamily: fonts.body,
                fontWeight: 'bold',
                '&:hover': { backgroundColor: colors.buttonHover },
              }}
              href="https://sportzilla.gg/referrer/"
              target="_blank"
            >
              Visit SportZilla
            </Button>
          </Grid>
          <Grid item xs={12} md={4}>
            <EmojiEventsIcon sx={{ fontSize: '4rem', color: colors.accent, marginBottom: '1rem' }} />
            <Button
              variant="contained"
              color="primary"
              sx={{
                width: '100%',
                backgroundColor: colors.accent,
                padding: '1rem',
                textTransform: 'none',
                fontFamily: fonts.body,
                fontWeight: 'bold',
                '&:hover': { backgroundColor: colors.buttonHover },
              }}
              href="https://i.pokerbros.net/6HdSouEvUDb"
              target="_blank"
            >
              Visit PokerBros
            </Button>
          </Grid>
          <Grid item xs={12} md={4}>
            <AccountBalanceIcon sx={{ fontSize: '4rem', color: colors.accent, marginBottom: '1rem' }} />
            <Button
              variant="contained"
              color="primary"
              sx={{
                width: '100%',
                backgroundColor: colors.accent,
                padding: '1rem',
                textTransform: 'none',
                fontFamily: fonts.body,
                fontWeight: 'bold',
                '&:hover': { backgroundColor: colors.buttonHover },
              }}
              href="https://depositzilla.gg"
              target="_blank"
            >
              Visit DepositZilla
            </Button>
          </Grid>
        </Grid>
        <LineDivider />
      </SectionContainer>
      {/* Footer */}
      <Box mt={2} textAlign="center" py={2} sx={{ backgroundColor: colors.background, color: colors.textSecondary }}>
        <Typography variant="body2">
          © 2024 DepositZilla. All rights reserved.
        </Typography>
      </Box>
    </GradientBackground>
  );
};

export default Main;
